import request from '@/utils/request'
import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//配置list
export function detail(params) {
	return loginRequest({
    url: '/frontend/website/aowei/landdata',
		method: 'post',
		data: Qs.stringify(params)
	})
}
